import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.payload = {};
    this.completedAnswerParts = JSON.parse(this.element.dataset.completedAnswerParts);
    this.requiredCount = parseInt(this.element.dataset.requiredAnswerPartsCount);
    this.questionnaireGuid = this.element.dataset.questionnaireGuid;
    this.answerId = this.element.dataset.answerId;
  }

  click(e) {
    const target = e.target;
    const answerFieldName = target.dataset.answerFieldName;
    if (answerFieldName) {
      this.payload[`answer[${answerFieldName}]`] = target.dataset.answerValue;
      if (!this.completedAnswerParts.includes(target.dataset.answerFieldName)) {
        this.completedAnswerParts.push(answerFieldName);
      }
    }

    if (this.completedAnswerParts.length >= this.requiredCount) {
      const groupContainer = target.closest('.answer-group-container');
      if (groupContainer) {
        groupContainer.querySelectorAll('.answer-tick').forEach((element) => {
          element.classList.add('d-none');
        });
      }

      const tickElement = this.element.querySelector('.answer-tick');
      tickElement.classList.remove('d-none');

      const formData = new FormData();
      Object.keys(this.payload).forEach(key => formData.append(key, this.payload[key]));
      formData.append('answer[question_id]', this.element.dataset.questionId);
      formData.append('authenticity_token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));

      let url = `/questionnaires/${this.questionnaireGuid}/answers`;
      if (this.answerId) {
        url = `${url}/${this.answerId}`;
      }
      fetch(url, {
          headers: { Accept: "text/vnd.turbo-stream.html" },
          method: this.answerId ? 'PUT' : 'POST',
          body: formData
        }).then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html));

    }
  }
}
