import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  click(e) {
    const target = e.target;
    const allAnswers = document.querySelectorAll(`[data-question-group-name="${target.dataset.questionGroupName}"]`);
    const targetAnswerValue = parseInt(target.dataset.answerValue);
    const checkedIconTypes = target.dataset.checkedIconTypes.split(' ');
    const uncheckedIconType = target.dataset.uncheckedIconType;
    allAnswers.forEach((answer) => {
      const answerValue = parseInt(answer.dataset.answerValue);
      if (targetAnswerValue >= answerValue) {
        answer.classList.remove(uncheckedIconType);
        answer.classList.add(...checkedIconTypes);
      } else {
        answer.classList.remove(...checkedIconTypes);
        answer.classList.add(uncheckedIconType);
      }
    });
  }

}
